<template>
  <div class="home container">
    <div class="row align-items-center">
      <div class="col-12 col-md-10 col-lg-6">
        <h1 class="mb-2 text-emphasis">
          {{ $t("greeting") }}<span class="tinted"> Walmir Silva. </span>
        </h1>
        <h2 class="mb-3 text-caption">{{ $t("titles") }}</h2>
        <p>
          {{ $t("resume") }}
        </p>
        <div class="row justify-content-center">
          <div class="col-auto">
            <button type="button" class="btn btn-action my-3">
              {{ $t("textButtons.download") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { changeBackground } from "@/assets/js/background.js";
export default {
  name: "Home",
  created() {
    changeBackground("home");
  },
};
</script>
